<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>

    <div class="row justify-content-md-center">
      <!-- Filtros -->
      <div class="col-12">
        <filter-side-bar>
          <template slot="ContentSideBar">
            <form id="processes-list-filter" ref='processesListFilter' v-on:submit.prevent="applyFilter()">
              <h5 class="mb-0">Filtrar por:</h5>
              <div>
                <div>
                  <multiple-select :options="customerList" :id-field="'id_customer'" :placeholder="this.$i18n.t('form.customer.label')" :fetchOptions="customerFetchOptions" :value="filters.filCliente" />
                </div>
              </div>

              <div class="py-1 text-center">
                <div class="md-form mb-0">
                  <div class="row">
                    <div class="col-6">
                      <button type="submit" class="btn btn-primary btn-fill btn-block mr-2 ">{{ $t('filter.apply') }}
                      </button>
                    </div>
                    <div class="col-6">
                      <button type="button" v-on:click="resetFilter" class="btn btn-cancel btn-fill btn-block mr-2 ">
                        {{ $t('filter.reset') }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </template>
        </filter-side-bar>
      </div>

      <!-- Bloco com os Filtros Aplicados -->
      <div class="col-12 mb-3">
        <filter-container :activeFilterList="activeFilterList" />
      </div>

      <!-- Empresa -->

      <!-- Lista -->
      <div class="col-12 col-md-6">
        <div class="card card-fullheight">
          <div class="card-body">
            <h5 class="mb-4 mt-3">Empresas</h5>
            <custom-table :gridCols="tableCompaniesFieldsHeader" :gridData="companies" :showHeader="false"
                :showActionColumn="true" @customClick="editCompany($event)" :totalPages="totalPages" :currentPage="page" :itemsPerPage="contactPerPage"
              @onPageChanged="onCompanyPageChanged" :isLoading="isCompanyLoading">
              <template slot="ContentAction" slot-scope="row">
                <button class="btn btn-link" @click.stop="editCompany(row.item)">
                  <i class="text-primary ti-pencil font-20"></i>
                </button>
<!--                <button class="btn btn-link" @click.stop="isSuperAdmin ? onDeleteCompany(row.item) : ''" :class="{disabled: (!isSuperAdmin || row.id_customer === user.customer_id)}">-->
<!--                  <i class="text-primary ti-trash font-20"></i>-->
<!--                </button>-->
                <button class="btn btn-link" @click.stop="viewCompany(row.item)">
                  <i class="text-primary rc-Ativo-210 font-20"></i>
                </button>
              </template>
            </custom-table>
          </div>
        </div>
      </div>

      <!-- Detalhes -->
      <div class="col-12 col-md-6">
        <div class="card">
          <div class="card-body" :class="{'disabled': !formCompanyEnabled}">
            <h5 class="mb-4 mt-3">Detalhes da Empresa</h5>
            <div class="row">
              <div class="col-12">
                <custom-input
                  v-model="companyEdit.name"
                  name="companyEdit.name"
                  label="Nome"
                  type="text"
                  :disabled="!isSuperAdmin"
                  rootClassName="md-form mb-0"
                  inputClassName="md-form-control"
                  v-validate="{ required: true }"
                  :error="errors.first('companyEdit.name')">
                </custom-input>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <custom-textarea
                  v-model="companyEdit.address"
                  name="companyEdit.address"
                  label="Endereço"
                  type="text"
                  :readonly="formFieldsCompanyReadOnly"
                  rootClassName="md-form mb-0"
                  inputClassName="md-form-control"
                  :rows="3"
                  v-validate="{ required: false }"
                  background="#fff"
                  :error="errors.first('companyEdit.address')">
                </custom-textarea>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-4">
                <custom-input
                  v-model="companyEdit.cnpj"
                  name="companyEdit.cnpj"
                  label="CNPJ"
                  type="text"
                  :disabled="!isSuperAdmin"
                  rootClassName="md-form mb-0"
                  inputClassName="md-form-control"
                  v-validate="{ required: true, validCNPJ: true }"
                  :error="errors.first('companyEdit.cnpj')">
                </custom-input>
              </div>
              <div class="col-12 col-md-8">
                <div class="md-form mb-0">
                <v-select label="nome" :readonly="formFieldsCompanyReadOnly" class="md-form-control"  :clearable="true" :searchable="true" @search="sectorFetchOptions" v-model="companyEdit.sector"  :options="sectorList" @input="onSelectSector"  >
                  <template slot="no-options">
                    {{ 'Nenhum resultado encontrado' }}
                  </template>
                  <template slot="option" slot-scope="option">
                    <div class="d-center">
                      {{ option.nome }}
                    </div>
                  </template>
                  <template slot="selected-option" slot-scope="option">
                    <div class="selected d-center">
                      {{ option.nome }}
                    </div>
                  </template>
                </v-select>
                <label :class="{'active': companyEdit.sector }">Setor</label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <custom-check-radio
                  :listItems="[{text: 'Ativo', value: 1}]"
                  v-model="companyEdit.status"
                  name="companyEdit.status"
                  type="checkbox"
                  :disabled="!isSuperAdmin"
                  inputClassName="p-switch p-fill mt-4"
                  stateClassName="p-primary"
                  v-validate="{ required: true }"
                  :error="errors.first('companyEdit.status')">
                </custom-check-radio>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <button type="button" class="btn btn-primary btn-sm btn-fill btn-rounded mt-4 pull-right" @click="onSaveCompany">Atualizar</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--  Contatos  -->
    <div class="row justify-content-md-center">

      <!-- Lista -->
      <div class="col-12 col-md-6">
        <div class="card card-fullheight">
          <div class="card-body">
            <h5 class="mb-4 mt-3">
              Contato
              <!--<a href="javascript:;" class="btn btn-success btn-sm btn-fill btn-rounded pull-right" :class="{disabled: !(isModerator || isSuperAdmin)}">Adicionar</a>-->
            </h5>
            <!-- <custom-table :gridCols="tableContactsFieldsHeader" :gridData="contacts" :showHeader="false" :showActionColumn="true"> -->
            <custom-table :gridCols="tableContactsFieldsHeader" :gridData="contacts" :showHeader="false"
              :showActionColumn="true" :totalPages="totalPagesContact" :currentPage="pageContact" :itemsPerPage="6"
              @onPageChanged="onContactPageChanged" :isLoading="isContactLoading"
              @customClick="editContact($event)"
            >

              <template slot="fullname" slot-scope="row">
                {{ row.item.name }} {{ row.item.last_name }}
              </template>
              <template slot="ContentAction" slot-scope="row">
                <button class="btn btn-link" @click.stop="editContact(row.item)" :class="{disabled: (validation(enableEdit, row.item.id, user.id))}">
                  <i class="text-primary ti-pencil font-20"></i>
                </button>
                <!-- <button class="btn btn-link" @click.stop="onDeleteContact" :disabled="!isSuperAdmin"> -->
<!--                <button class="btn btn-link" @click.stop="!validation(enableEdit, row.item.id, user.id) ? onDeleteContact(row.item) : ''" :class="{disabled: (validation(enableEdit, row.item.id, user.id))}">-->
<!--                  <i class="text-primary ti-trash font-20"></i>-->
<!--                </button>-->
                <button class="btn btn-link" @click.stop="viewContact(row.item)">
                  <i class="text-primary rc-Ativo-210 font-20"></i>
                </button>
              </template>
            </custom-table>
          </div>
        </div>
      </div>
      <!-- Detalhes -->
      <div class="col-12 col-md-6">
        <div class="card">
          <div class="card-body" :class="{'disabled': !formContactEnabled}">
            <h5 class="mb-4 mt-3">Detalhes do Contato</h5>
            <div class="row">
              <div class="col-12">
                <custom-input
                  v-model="contactEdit.email"
                  name="contactEdit.email"
                  label="E-mail"
                  type="text"
                  readonly="readonly"
                  rootClassName="md-form mb-0"
                  inputClassName="md-form-control"
                  v-validate="{ required: true }"
                  :error="errors.first('contactEdit.email')">
                </custom-input>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <custom-input
                  v-model="contactEdit.name"
                  name="contactEdit.name"
                  label="Nome"
                  type="text"
                  rootClassName="md-form mb-0"
                  inputClassName="md-form-control"
                  v-validate="{ required: true }"
                  :error="errors.first('contactEdit.name')">
                </custom-input>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <custom-input
                  v-model="contactEdit.last_name"
                  name="contactEdit.last_name"
                  label="Sobrenome"
                  type="text"
                  rootClassName="md-form mb-0"
                  inputClassName="md-form-control"
                  v-validate="{ required: true }"
                  :error="errors.first('contactEdit.last_name')">
                </custom-input>
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-md-5">
                <custom-input-date
                  v-model="contactEdit.birth_date"
                  name="contactEdit.birth_date"
                  label="Data Nascimento"
                  type="text"
                  v-on:input="contactEdit.birth_date = $event"
                  v-validate="{ required: false }"
                  :error="errors.first('contactEdit.birth_date')">
                </custom-input-date>

              </div>

                <div class="col-12 col-md-7">
                  <label style="margin-right: 20px; margin-top: 25px;">Sexo</label>
                  <custom-check-radio
                    :listItems="[{text: 'Feminino', value: 'f'}, {text: 'Masculino', value: 'm'}]"
                    v-model="contactEdit.gender"
                    name="contactEdit.gender"
                    type="radio"
                    rootClassName="form-group d-inline"
                    inputClassName="p-default p-round"
                    v-validate="{ required: false }"
                    :error="errors.first('contactEdit.gender')">
                  </custom-check-radio>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-6">
                  <custom-input
                    v-model="contactEdit.sector"
                    name="contactEdit.sector"
                    label="Departamento"
                    type="text"
                    rootClassName="md-form mb-0"
                    inputClassName="md-form-control"
                    v-validate="{ required: true }"
                    :error="errors.first('contactEdit.sector')">
                  </custom-input>
                </div>
                <div class="col-12 col-md-6">
                  <custom-input
                    v-model="contactEdit.office"
                    name="contactEdit.office"
                    label="Cargo"
                    type="text"
                    rootClassName="md-form mb-0"
                    inputClassName="md-form-control"
                    v-validate="{ required: true }"
                    :error="errors.first('contactEdit.office')">
                  </custom-input>
                </div>
              </div>
            <div class="row">
              <div class="col-12 col-md-6">
                <custom-input
                  v-model="contactEdit.phone"
                  name="contactEdit.phone"
                  label="Telefone"
                  type="text"
                  rootClassName="md-form mb-0"
                  inputClassName="md-form-control"
                  v-validate="{ required: true }"
                  vMask="(##) #####-####"
                  :error="errors.first('contactEdit.phone')">
                </custom-input>
              </div>

              <div class="col-12 col-md-6">
                <custom-input
                  v-model="contactEdit.ramal"
                  name="contactEdit.ramal"
                  label="Ramal"
                  type="text"
                  rootClassName="md-form mb-0"
                  inputClassName="md-form-control"
                  v-validate="{ required: true }"
                  :error="errors.first('contactEdit.ramal')">
                </custom-input>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <custom-input
                  v-model="contactEdit.cellPhone"
                  name="contactEdit.cellPhone"
                  label="Celular"
                  type="text"
                  rootClassName="md-form mb-0"
                  inputClassName="md-form-control"
                  vMask="(##) #####-####"
                  v-validate="{ required: true }"
                  :error="errors.first('contactEdit.cellPhone')">
                </custom-input>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <custom-input
                  v-model="contactEdit.linkedin"
                  name="contactEdit.linkedin"
                  label="Linkedin/"
                  type="text"
                  rootClassName="md-form mb-0"
                  inputClassName="md-form-control"
                  v-validate="{ required: true }"
                  :error="errors.first('contactEdit.linkedin')">
                </custom-input>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <custom-input
                  v-model="contactEdit.facebook"
                  name="contactEdit.facebook"
                  label="Facebook/"
                  type="text"
                  rootClassName="md-form mb-0"
                  inputClassName="md-form-control"
                  v-validate="{ required: true }"
                  :error="errors.first('contactEdit.facebook')">
                </custom-input>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <custom-input
                  v-model="contactEdit.instagram"
                  name="contactEdit.instagram"
                  label="Instagram/"
                  type="text"
                  rootClassName="md-form mb-0"
                  inputClassName="md-form-control"
                  v-validate="{ required: true }"
                  :error="errors.first('contactEdit.instagram')">
                </custom-input>
              </div>
            </div>

            <div class="row" v-if="moderator">
              <div class="col-12">
                <custom-input
                  v-model="contactEdit.password"
                  name="contactEdit.password"
                  label="Senha"
                  type="password"
                  rootClassName="md-form mb-0"
                  inputClassName="md-form-control"
                  v-validate="{ required: true }"
                  :error="errors.first('contactEdit.password')">
                </custom-input>
              </div>
              <div class="col-12">
                <custom-input
                  v-model="contactEdit.repeatPassword"
                  name="contactEdit.repeatPassword"
                  label="Repita a Senha"
                  type="password"
                  rootClassName="md-form mb-0"
                  inputClassName="md-form-control"
                  v-validate="{ required: true, confirmed: contactEdit.password }"
                  :error="(passwordSubmitted)?errors.first('contactEdit.repeatPassword'):''">
                </custom-input>
              </div>
            </div>

            <!-- Permissions -->
            <div class="row">
              <div class="col-4">
                <custom-switch
                  :listItems="[{text: 'Ativo', value: 1}]"
                  v-model="contactEdit.status"
                  name="contactEdit.status"
                  type="checkbox"
                  stateClassName="p-primary"
                  :error="errors.first('contactEdit.status')">
                </custom-switch>
              </div>
              <div class="col-4" v-if="moderator">
                <custom-switch
                  :listItems="[{text: 'Moderador', value: 1}]"
                  v-model="contactEdit.moderator"
                  name="contactEdit.moderator"
                  type="checkbox"
                  stateClassName="p-primary"
                  :error="errors.first('contactEdit.moderator')">
                </custom-switch>
              </div>
              <div class="col-4" v-if="allowActiveInvoiceOnline">
                <custom-switch
                  :listItems="[{text: 'Fatura Online', value: 1}]"
                  v-model="contactEdit.allowOnlineInvoice"
                  name="contactEdit.allowOnlineInvoice"
                  type="checkbox"
                  stateClassName="p-primary"
                  :error="errors.first('contactEdit.allowOnlineInvoice')">
                </custom-switch>
              </div>
              <div class="col-4" v-if="allowActiveProposalOnline">
                <custom-switch
                  :listItems="[{text: 'Cotações Online', value: 1}]"
                  v-model="contactEdit.allowOnlineProposal"
                  name="contactEdit.allowOnlineProposal"
                  type="checkbox"
                  stateClassName="p-primary"
                  :error="errors.first('contactEdit.allowOnlineProposal')">
                </custom-switch>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <button type="button" class="btn btn-primary btn-sm btn-fill btn-rounded mt-4 pull-right" @click="onSaveContact">Atualizar</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Components

// Form
import MultipleSelect from '@/components/Forms/MultipleSelect.vue'
import CustomSwitch from '@/components/Forms/CustomSwitch.vue'

// Services
import CustomerService from '@/services/CustomerService'

// Services
import SectorService from '@/services/SectorService'
import CompanyService from '@/services/CompanyService'

// import UserService from '@/services/UserService'
import CustomInput from '@/components/Forms/CustomInput.vue'
import CustomTextarea from '@/components/Forms/CustomTextarea.vue'
import CustomTable from '@/components/Table/CustomTable.vue'
import CustomInputDate from '@/components/Forms/CustomInputDate.vue'
import CustomCheckRadio from '@/components/Forms/CustomCheckRadio.vue'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

// Filter
import FilterSideBar from '@/components/FilterSideBar.vue'
import FilterContainer from '@/components/FilterContainer'

import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

import Vue from 'vue'
// @ is an alias to /src
import VeeValidate from 'vee-validate'
import { locale, validCNPJ } from '@/utils/validator'
VeeValidate.Validator.localize({ 'pt_BR': locale })
VeeValidate.Validator.extend('validCNPJ', validCNPJ)
Vue.use(VeeValidate, { locale: 'pt_BR', fieldsBagName: 'formFields' })

export default {
  name: 'CompanyList',
  data () {
    return {
      customerList: [],
      sectorList: [],
      movementTypeList: [],
      contactPerPage: 13,
      passwordSubmitted: false,
      companyEdit: {},
      companies: [],
      filter: {},
      activeFilterList: {
        customer: [],
        id_status: null
      },
      contactEdit: {},
      contacts: [],
      isLoading: true,
      fullPage: true,
      formCompanyEnabled: false,
      formContactEnabled: false,
      formFieldsCompanyReadOnly: true,
      formFieldsContactReadOnly: true,
      tableCompaniesFieldsHeader: [
        { field: 'name', display: 'Nome', size: '80%' }
      ],
      tableContactsFieldsHeader: [
        { field: 'fullname', display: 'Nome', size: '80%' }
      ],
      totalPages: 0,
      page: 1,
      isCompanyLoading: true,
      totalPagesContact: 0,
      pageContact: 1,
      moderator: false,
      isContactLoading: false
    }
  },
  components: {
    vSelect,
    CustomInput,
    CustomInputDate,
    CustomTextarea,
    CustomSwitch,
    CustomCheckRadio,
    Loading,
    CustomTable,
    FilterSideBar,
    FilterContainer,
    MultipleSelect
  },
  // Apply filters to local filter
  beforeMount () {
    this.buildFilters()
  },
  mounted () {
    this.$store.dispatch('SetShowFilter', true)
    this.$store.dispatch('setShowSearch', false)

    if (this.user) {
      this.showCompanies(this.filter)
    }
  },
  methods: {
    // Sector
    /**
     * Triggered when the customer search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    validation (superAdm, itemId, userId) {
      if (itemId === userId) {
        return false
      } else if (superAdm === true) {
        return false
      } else {
        return true
      }
    },
    movementTypeFetchOptions (search, loading) {
      let me = this
      loading(true)
      let filters = {
        'searchTerm': search
      }

      SectorService.getSectors(filters).then(res => {
        me.movementTypeList = res.data.data
        loading(false)
      })
    },
    onSelectmovementType (val) {
      this.companyEdit.movementTypePref = val
    },
    sectorFetchOptions (search, loading) {
      let me = this
      loading(true)
      let filters = {
        'searchTerm': search
      }

      SectorService.getSectors(filters).then(res => {
        me.sectorList = res.data.data
        loading(false)
      })
    },
    onSelectSector (val) {
      this.companyEdit.sector = val
    },
    // Filters
    buildFilters () {
      this.filter.customer = (this.filters.filCliente) ? this.filters.filCliente.map(x => (x.id)) : []
      this.filter.id_status = null
    },
    applyFilter () {
      this.customerList = []

      this.buildFilters()

      this.$store.dispatch('setAppliedFilter', this.filters)

      this.hideQuichSidebar()

      this.page = 1
      this.pageContact = 1

      this.showCompanies(this.filter)
    },
    resetFilter (event) {
      event.preventDefault()
      this.hideQuichSidebar()
      // TODO: Ezequiel
      this.filters.filCliente = []
      this.$store.dispatch('setAppliedFilter', this.filters)

      this.buildFilters()

      this.page = 1
      this.pageContact = 1

      this.showCompanies(this.filter)
    },
    hideQuichSidebar () {
      const sideBar = document.querySelector('#quick-sidebar')
      document.body.classList.remove('has-backdrop')
      sideBar.classList.remove('shined')
    },
    formatStatus (value) {
      if (!value) {
        return ' - '
      }

      let statusList = [
        {
          'id': 1,
          'label': this.$i18n.t('form.task.status.active'),
          'color': '#5ec65f'
        },
        {
          'id': 2,
          'label': this.$i18n.t('form.task.status.inactive'),
          'color': '#ababab'
        },
        {
          'id': 3,
          'label': this.$i18n.t('form.task.status.expired'),
          'color': '#f1ba26'
        }
      ]

      let status = statusList.find(obj => {
        if (obj.id === value) {
          return obj
        }
      })

      return '<span style="color: ' + status.color + '">' + status.label + '</span>'
    },
    // End Filters
    showCompanies (filters) {
      var _this = this
      _this.isCompanyLoading = true
      CompanyService.getCompanies(filters, this.page, 6).then(customers => {
        _this.companies = customers.data.data
        _this.totalPages = customers.data.total_pages
        _this.isLoading = false
        _this.isCompanyLoading = false
      })
    },
    onCompanyPageChanged (page) {
      this.page = page
      this.showCompanies(this.filter)
    },
    editCompany (company) {
      let _this = this
      this.pageContact = 1
      this.companyEdit = company
      this.formCompanyEnabled = true
      this.formFieldsCompanyReadOnly = false

      SectorService.getSectors().then(res => {
        _this.sectorList = res.data.data
        _this.showContacts(_this.companyEdit.id_customer)
      })

      this.moderator = this.isModerator

      if (!this.moderator) {
        CustomerService.getUserCustomerModerator(company.id_customer).then(res => {
          _this.moderator = res.data.data
        })
      }
    },
    onDeleteCompany (company) {
      if (this.isSuperAdmin) {
        var _this = this
        this.$alertSwal.modalConfirm('', 'Você tem certeza de que deseja deletar esta empresa.<br />Está certo desta ação?', 'warning', 'Sim', 'Não', (e) => this.deleteCompany(company)).then((result) => {
          if (result.value) {
            this.$alertSwal.toast('Registro Deletado com sucesso!')
            _this.showCompanies(this.filter)
          }
        })
      }
    },
    async deleteCompany (company) {
      var _this = this
      company.deleted = 1
      this.isLoading = true

      await CompanyService.setCompany(company.id_customer, company).then(companies => {
        // _this.showCompanies()
        _this.companies = companies.data.data
        _this.isLoading = false
        _this.companyEdit = {}
        _this.formCompanyEnabled = false
        _this.formFieldsCompanyReadOnly = true
      })
    },
    viewCompany (company) {
      let _this = this

      _this.pageContact = 1

      _this.isLoading = true
      _this.companyEdit = company
      _this.formCompanyEnabled = false
      _this.formFieldsCompanyReadOnly = true

      if (this.companyEdit.sector !== null) {
        SectorService.getSector(_this.companyEdit.sector.id).then(res => {
          _this.sectorList = [res.data]
          _this.showContacts(_this.companyEdit.id_customer)
        })
      } else {
        SectorService.getSectors().then(res => {
          _this.sectorList = res.data.data
          _this.showContacts(_this.companyEdit.id_customer)
        })
      }
      this.moderator = this.isModerator

      if (!this.moderator) {
        CustomerService.getUserCustomerModerator(company.id_customer).then(res => {
          _this.moderator = res.data.data
        })
      }
    },
    onSaveCompany () {
      var _this = this

      _this.isLoading = true

      let company = {
        name: this.companyEdit.name,
        address: this.companyEdit.address,
        cnpj: this.companyEdit.cnpj,
        sector: (this.companyEdit.sector) ? this.companyEdit.sector.id : null,
        status: this.companyEdit.status,
        deleted: this.companyEdit.deleted
      }

      CompanyService.setCompany(_this.companyEdit.id_customer, company).then(companies => {
        _this.showCompanies(this.filter)
        _this.companies = companies.data.data
        _this.companyEdit = {}
        _this.formCompanyEnabled = false
        _this.formFieldsCompanyReadOnly = true
      }).finally(function () {
        _this.isLoading = false
      })
    },
    /* Contacts */
    showContacts (companyId) {
      var _this = this

      _this.formContactEnabled = false
      _this.formFieldsContactReadOnly = true
      _this.contactEdit = {}
      _this.isContactLoading = true
      CompanyService.getCompanyUsers(companyId, this.pageContact, this.contactPerPage).then(contacts => {
        _this.contacts = contacts.data.data
        _this.totalPagesContact = contacts.data.total_pages
        _this.isContactLoading = false
        _this.isLoading = false
      })
    },
    onContactPageChanged (page) {
      this.pageContact = page
      this.isContactLoading = false
      this.showContacts(this.companyEdit.id_customer)
    },
    editContact (contact) {
      this.contactEdit = {}
      let active = this.validation(this.enableEdit, contact.id, this.user.id)

      if (!active) {
        // Clonando contato para não editar o original
        this.contactEdit = Object.assign({}, contact)
        this.formContactEnabled = true
        this.formFieldsContactReadOnly = false
      }
    },
    onDeleteContact (contact) {
      var _this = this
      this.$alertSwal.modalConfirm('', 'Você tem certeza de que deseja deletar este contato.<br />Está certo desta ação?', 'warning', 'Sim', 'Não', (e) => this.deleteContact(contact)).then((result) => {
        if (result.value) {
          this.$alertSwal.toast('Registro Deletado com sucesso!')
          _this.showContacts(contact.id_customer)
        }
      })
    },
    async deleteContact (contact) {
      var _this = this
      _this.isLoading = true

      let data = {}
      // Person
      data.name = contact.name
      data.email = contact.email
      data.last_name = contact.last_name
      data.birth_date = contact.birth_date
      data.gender = contact.gender

      // Company
      data.sector = contact.sector
      data.office = contact.office
      data.phone = contact.phone
      data.ramal = contact.ramal
      data.cellPhone = contact.cellPhone

      // Social
      data.linkedin = contact.linkedin
      data.facebook = contact.facebook
      data.instagram = contact.instagram

      data.status = contact.status
      data.is_moderator = contact.is_moderator
      data.deleted = contact.deleted = 1

      await CompanyService.setCompanyUser(_this.companyEdit.id_customer, contact.id, data).then(contact => {
        // _this.showContacts(contact.id_customer)
        _this.contacts = contact.data.data
        _this.isLoading = false
        _this.contactEdit = {}
        _this.formContactEnabled = false
        _this.formFieldsContactReadOnly = true
      })
    },
    viewContact (contact) {
      this.contactEdit = contact
      this.formContactEnabled = false
      this.formFieldsContactReadOnly = true
    },
    onSaveNewPassword () {
      let _this = this

      _this.passwordSubmitted = true

      if (_this.contactEdit.password && _this.contactEdit.password === _this.contactEdit.repeatPassword) {
        _this.isLoading = true

        let data = {
          'password': _this.contactEdit.password
        }

        CompanyService.setCompanyUserPassword(_this.contactEdit.id, data).then(contact => {
          _this.$alertSwal.toast('Senha alterada com sucesso!')
          _this.passwordSubmitted = false
          _this.contactEdit.password = null
          _this.contactEdit.repeatPassword = null
          _this.contactEdit = {}
          _this.formContactEnabled = false
          _this.formFieldsContactReadOnly = true
        }).finally(function () {
          _this.isLoading = false
        })
      }
    },
    onSaveContact () {
      var _this = this

      _this.isLoading = true

      let data = {}
      // Person
      data.name = this.contactEdit.name
      data.email = this.contactEdit.email
      data.last_name = this.contactEdit.last_name
      data.birth_date = this.contactEdit.birth_date
      data.gender = this.contactEdit.gender

      // Company
      data.sector = this.contactEdit.sector
      data.office = this.contactEdit.office
      data.phone = this.contactEdit.phone
      data.ramal = this.contactEdit.ramal
      data.cellPhone = this.contactEdit.cellPhone

      // Social
      data.linkedin = this.contactEdit.linkedin
      data.facebook = this.contactEdit.facebook
      data.instagram = this.contactEdit.instagram

      data.status = (this.contactEdit.status) ? this.contactEdit.status : 2
      data.deleted = 0

      if (this.isModerator) {
        data.moderator = (this.contactEdit.moderator) ? this.contactEdit.moderator : 0
      }

      if (this.moderator && _this.contactEdit.password) {
        this.onSaveNewPassword()
        data.password = _this.contactEdit.password
      }

      if (this.allowActiveInvoiceOnline) {
        data.allowOnlineInvoice = _this.contactEdit.allowOnlineInvoice
      }

      if (this.allowActiveProposalOnline) {
        data.allowOnlineProposal = _this.contactEdit.allowOnlineProposal
      }

      CompanyService.setCompanyUser(_this.companyEdit.id_customer, _this.contactEdit.id, data).then(contact => {
        _this.showContacts(_this.companyEdit.id_customer)
        _this.contacts = contact.data.data
        _this.contactEdit = {}
        _this.formContactEnabled = false
        _this.formFieldsContactReadOnly = true
        _this.contactEdit.moderator = null
      }).finally(function () {
        _this.isLoading = false
      })
    },
    /**
     * Triggered when the customer search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    customerFetchOptions (search) {
      let _this = this

      return new Promise((resolve, reject) => {
        let filters = {
          'searchTerm': search
        }

        CustomerService.getCustomers(filters).then(res => {
          _this.customerList = res.data.data
          resolve(_this.customerList)
        }).catch(err => {
          reject(err)
        })
      })
    }
  },
  computed: {
    user () {
      return this.$store.getters.userInfo || null
    },
    enableEdit () {
      if (this.user) {
        return (this.user.roles.filter(role => role === 'ROLE_SUPER_ADMIN').length > 0 || this.user.roles.filter(role => role === 'ROLE_EMPLOYEE').length > 0 || this.moderator)
      } else {
        return false
      }
    },
    isSuperAdmin () {
      if (this.user) {
        return this.user.roles.filter(role => role === 'ROLE_SUPER_ADMIN').length > 0
      } else {
        return false
      }
    },
    allowActiveProposalOnline () {
      if (this.user) {
        if (this.user.roles.filter(role => role === 'ROLE_SUPER_ADMIN').length > 0) {
          return true
        } else if (this.user.roles.filter(role => role === 'ROLE_FINANTIAL').length > 0) {
          return true
        } else if (this.user.roles.filter(role => role === 'ROLE_FINANCIAL_OPERATING').length > 0) {
          return true
        } else if (this.user.roles.filter(role => role === 'ROLE_EMPLOYEE').length > 0) {
          return true
        }

        return false
      } else {
        return false
      }
    },
    allowActiveInvoiceOnline () {
      if (this.user) {
        if (this.user.roles.filter(role => role === 'ROLE_SUPER_ADMIN').length > 0) {
          return true
        } else if (this.user.roles.filter(role => role === 'ROLE_FINANTIAL').length > 0) {
          return true
        } else if (this.user.roles.filter(role => role === 'ROLE_FINANCIAL_OPERATING').length > 0) {
          return true
        }

        return false
      } else {
        return false
      }
    },
    isEmployee () {
      if (this.user) {
        return this.user.roles.filter(role => role === 'ROLE_EMPLOYEE').length > 0
      } else {
        return false
      }
    },
    isModerator () {
      if (this.user) {
        let moderator = false
        if (this.isSuperAdmin || this.isEmployee || this.moderator) {
          moderator = true
        }
        return moderator
      } else {
        return false
      }
    },
    filters () {
      if (this.$Cookie.get('_userStoredFilters_')) {
        return JSON.parse(this.$Cookie.get('_userStoredFilters_'))
      } else {
        return null
      }
    },
    refreshFilter () {
      return this.$store.getters.getRefreshFilter || null
    }
  },
  watch: {
    contactEdit (newVal, oldVal) {
    },
    user () {
      this.showCompanies(this.filter)
    },
    refreshFilter: function () {
      if (this.refreshFilter) {
        this.applyFilter()
        this.$store.dispatch('setRefreshFilter', false)
      }
    }
  }
}
</script>

<style scoped>

  .table tr:hover,
  .table tr:hover button > i {
    color: blue !important;
    /* cursor: pointer; */
  }

  button:disabled,
  button:disabled > i,
  button.disabled,
  button.disabled > i {
    pointer-events: inherit !important;
    cursor: not-allowed !important;
    /* color: greenyellow !important; */
  }

  .table th, .table td {
    padding: 0.65rem !important;
    cursor: pointer;
  }

  .btn-link {
    padding: 0 7px !important;
  }

  .table-responsive {
    max-height: 335px;
    overflow: hidden;
  }

  div.disabled
  {
    pointer-events: none;
    /* position:absolute;
    width:100%;
    height:100%;
    background-color:transparent;
    z-index:1;
    top:0; */
  }

  .table th, .table td {
    padding: 0.65rem;
  }
</style>
