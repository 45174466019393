<template>
  <div class="table-responsive px-0">
    <loading :active="isLoading" :can-cancel="false" :is-full-page="false"></loading>
    <table class="table">
      <thead v-if="showHeader">
        <tr>
          <th v-for="(col, index) in gridCols" :key="index" :width="col.size">{{ col.display }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in gridData" :key="index" @click="customClick(item)">
          <td v-for="(col, indexCol) in gridCols" :key="indexCol" :width="col.size">
            <span v-if="item.hasOwnProperty(col.field)">{{ item[col.field] }}</span>
            <slot v-else :name="col.field" :item="item"></slot>
          </td>
          <td v-if="showActionColumn" class="actions">
            <slot name="ContentAction" :item="item">
            </slot>
          </td>
        </tr>
        <tr v-show="!gridData.length">
          <td colspan="4" class="text-center text-danger"><strong>Nenhum registro encontrado</strong></td>
        </tr>
      </tbody>
    </table>
    <table-pagination v-if="totalPages > 1" :totalPages="totalPages" :perPage="itemsPerPage" :currentPage="currentPage" @onPageChanged="onPageChanged"/>
  </div>
</template>

<script>
import TablePagination from './TablePagination'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
// import { totalmem } from 'os';
export default {
  name: 'CustomTable',
  data () {
    return {
      filterSearch: ''
    }
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    totalPages: {
      type: Number,
      required: false,
      default: 0
    },
    currentPage: {
      type: Number,
      required: false,
      default: 1
    },
    itemsPerPage: {
      type: Number,
      required: false,
      default: 10
    },
    gridCols: {
      type: Array,
      required: true,
      default: () => []
    },
    gridData: {
      type: Array,
      required: true,
      default: () => []
    },
    showActionColumn: {
      type: Boolean,
      default: false
    },
    showHeader: {
      type: Boolean,
      default: false
    }
  },
  components: {
    TablePagination,
    Loading
  },
  methods: {
    onPageChanged (page) {
      this.$emit('onPageChanged', page)
      // this.currentPage = page
    },
    showField (item, item2) {
      return 'TESTE'
    },
    customClick (item) {
      this.$emit('customClick', item)
    }
  }
}
</script>

<style scoped>
  .table-responsive {
    padding: 0px;
  }

  .table-responsive {
    max-height: none !important;
    overflow: hidden;
  }

</style>
