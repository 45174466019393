<template>
  <nav>
    <!-- <ul class="pagination pagination-circle mb-5" :class="{'d-none': (totalPages === 1 || totalPages === 0)}"> -->
    <ul class="pagination pagination-circle mb-3">
      <li class="page-item" :class="{'disabled': (1 === currentPage)}">
        <a href="javascript:;" @click="onClickFirstPage(1 === currentPage)" class="page-link"><i class="fa fa-angle-double-left"></i></a>
      </li>

      <li class="page-item" :class="{'disabled': (1 === currentPage)}">
        <a href="javascript:;" @click="onClickPreviousPage" class="page-link"><i class="fa fa-angle-left"></i></a>
      </li>

      <li v-for="(page, index) in pages" :key="index" :class="{'active': (page.name === currentPage)}" class="paginate_button page-item">
        <a href='javascript:;' @click="onClickPage(page.name)" class="page-link"> {{ page.name }} </a>
      </li>

      <li class="page-item" :class="{'disabled': (totalPages === currentPage)}">
        <a href="javascript:;" @click="onClickNextPage" class="page-link"><i class="fa fa-angle-right"></i></a>
      </li>

      <li class="page-item" :class="{'disabled': (totalPages === currentPage)}">
        <a href="javascript:;" @click="onClickLastPage" class="page-link"><i class="fa fa-angle-double-right"></i></a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'TablePagination',
  props: {
    maxVisibleButtons: {
      type: Number,
      required: false,
      default: 3
    },
    // total: {
    //   type: Number,
    //   required: true,
    //   default: 0
    // },
    currentPage: {
      type: Number,
      required: true,
      default: 1
    },
    totalPages: {
      type: Number,
      required: false,
      default: 0
    }
  },
  computed: {
    startPage () {
      if (this.currentPage === 1) {
        return 1
      }
      if (this.currentPage === this.totalPages) {
        return (this.totalPages - this.maxVisibleButtons + 1) === 0 ? 1 : (this.totalPages - this.maxVisibleButtons + 1)
      }

      return this.currentPage - 1
    },
    endPage () {
      return Math.min(this.startPage + this.maxVisibleButtons - 1, this.totalPages)
    },
    pages () {
      const range = []

      for (let i = this.startPage; i <= this.endPage; i++) {
        range.push({
          name: i,
          isDisabled: i === this.currentPage
        })
      }
      return range
    },
    isInFirstPage () {
      return this.currentPage === 1
    },
    isInLastPage () {
      return this.currentPage === this.totalPages
    }
  },
  methods: {
    onClickFirstPage () {
      if (this.currentPage > 1) {
        this.$emit('onPageChanged', 1)
      }
    },
    onClickPreviousPage () {
      if (this.currentPage > 1) {
        this.$emit('onPageChanged', this.currentPage - 1)
      }
    },
    onClickPage (page) {
      this.$emit('onPageChanged', page)
    },
    onClickNextPage () {
      if (this.currentPage < this.totalPages) {
        this.$emit('onPageChanged', this.currentPage + 1)
      }
    },
    onClickLastPage () {
      if (this.currentPage < this.totalPages) {
        this.$emit('onPageChanged', this.totalPages)
      }
    },
    isPageActive (page) {
      return this.currentPage === page
    }
  }
}
</script>

<style>

</style>
